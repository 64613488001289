<template>
  <div>
    <h2>
      {{ $t('reportError.car.subtype') }}
    </h2>
    <v-row class="fill-height text-center justify-center">
      <v-col
        v-for="(car, i) in carData"
        :key="i"
        sm="4"
      >
        <select-card
          :creation-data="car"
          @click="decideCar"
        />
      </v-col>
      <v-col sm="12">
        <v-btn
          color="primary"
          elevation="6"
          x-large
          @click="goBack()"
        >
          {{ $t('reportError.back') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SelectCard from '../SelectCard'

export default {
  name: 'CarSubType',
  components: {
    SelectCard
  },
  props: {
    carTypeName: {
      type: String,
      required: true
    },
    goBackToPage: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      carData: []
    }
  },
  created () {
    this.getCarData()
  },
  methods: {
    async getCarData () {
      try {
        const { data } = await this.$axios.get('/car-types/?select=id,name,subType')
        const filteredData = data.filter(e => e.name === this.carTypeName)
        if (!filteredData[0].subType) {
          this.$emit('subtype-select-car', { id: filteredData[0].id, default: true })
          return
        }
        this.carData = filteredData.map(e => ({ name: e.subType, id: e }))
      } catch (err) {
        console.error(err)
      }
    },
    decideCar (chosenSubtype) {
      this.$emit('subtype-select-car', chosenSubtype)
    },
    goBack () {
      this.$emit('go-back', this.goBackToPage)
    }
  }
}
</script>
