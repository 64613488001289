<template>
  <div>
    <h2>
      {{ $t('reportError.car.type') }}
    </h2>
    <v-row class="fill-height text-center justify-center">
      <v-col
        v-for="(car, i) in carData"
        :key="i"
        sm="4"
      >
        <select-card
          :creation-data="car"
          @click="decideCarType"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SelectCard from '../SelectCard'

export default {
  name: 'CarTypeSelect',
  components: {
    SelectCard
  },
  data () {
    return {
      carData: []
    }
  },
  created () {
    this.getCarData()
  },
  methods: {
    async getCarData () {
      try {
        const { data } = await this.$axios.get('/car-types/?select=id,name,description')
        this.carData = [...new Set(data.map(e => e.name))].map(e => ({ name: e, id: e }))
      } catch (err) {
        console.error(err)
      }
    },
    decideCarType (carId) {
      this.$emit('select-car', carId)
    }
  }
}
</script>
