<template>
  <div>
    <h2>
      {{ $t('reportError.car.number') }}
    </h2>
    <v-row class="fill-height text-center justify-center">
      <v-col sm="6">
        <v-text-field
          v-model="additionalInfo"
          required
        />
      </v-col>
      <v-col sm="12">
        <v-btn
          color="primary"
          elevation="6"
          x-large
          @click="goBack()"
        >
          {{ $t('reportError.back') }}
        </v-btn>
        <v-btn
          class="ml-3"
          color="primary"
          elevation="6"
          x-large
          @click="emit()"
        >
          {{ $t('reportError.ok') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'AddInformation',
  props: {
    goBackToPage: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      additionalInfo: ''
    }
  },
  methods: {
    goBack () {
      this.$emit('go-back', this.goBackToPage)
    },
    emit () {
      this.$emit('add-information', this.additionalInfo)
    }
  }
}
</script>
