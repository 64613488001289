<template>
  <v-card
    style="cursor: pointer; height: 100%"
    class="mx-auto"
    max-width="344"
    @click="$emit('click', creationData.id)"
  >
    <v-card-text>
      <p class="display-1 text--primary">
        {{ creationData.name }}
      </p>
      <div
        v-if="creationData.description"
        class="text-subtitle-1 mb-2 mt-2"
      >
        {{ $t('card.description') }}:
      </div>
      <div
        v-if="creationData.description"
        class="text--primary"
      >
        {{ creationData.description }}
      </div>
      <div
        v-if="creationData.location"
        class="text-subtitle-1 mb-2 mt-5"
      >
        {{ $t('card.location') }}:
      </div>
      <div
        v-if="creationData.location"
        class="text--primary"
      >
        {{ creationData.location }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'SelectCard',
  props: {
    creationData: {
      type: Object,
      required: true
    }
  }
}
</script>
