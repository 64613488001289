<template>
  <div>
    <h2>
      {{ $t('reportError.completePage.description') }}
    </h2>
    <v-row class="fill-height text-center justify-center">
      <v-col sm="6">
        <v-textarea
          v-model="description"
          clearable
          clear-icon="mdi-close-circle"
          :label="$t('reportError.completePage.label')"
          @blur="addDescription"
        />
      </v-col>
      <v-col sm="12">
        <v-btn
          color="primary"
          elevation="6"
          x-large
          @click="goBack()"
        >
          {{ $t('reportError.back') }}
        </v-btn>
        <v-btn
          class="ml-3"
          color="primary"
          elevation="6"
          x-large
          @click="report()"
        >
          {{ $t('reportError.completePage.action') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'SelectErrorCategory',
  props: {
    errorData: {
      type: Object,
      required: true
    },
    goBackToPage: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      description: this.errorData.description
    }
  },
  methods: {
    addDescription () {
      this.$emit('add-description', this.description)
    },
    goBack () {
      this.$emit('go-back', this.goBackToPage)
    },
    report () {
      this.$emit('report-error')
    }
  }
}
</script>
