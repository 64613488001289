<template>
  <v-row class="text-center fill-height">
    <v-col cols="12">
      <h1>{{ $t('reportError.title') }}</h1>
      <v-divider />
      <component
        :is="activeComponent"
        :error-data="errorData"
        :go-back-to-page="goBackToPage"
        :car-type-name="errorData.carType"
        @select-car="selectCarType"
        @subtype-select-car="selectCarSubtype"
        @add-information="addInformation"
        @add-description="addDescription"
        @report-error="reportError"
        @go-back="goBackTo"
      />
    </v-col>
  </v-row>
</template>

<script>
import CarTypeSelect from '@/components/Car/ErrorReport/CarTypeSelect'
import CarSubtypeSelect from '@/components/Car/ErrorReport/CarSubtype'
import AddDescription from '@/components/Car/ErrorReport/AddDescription'
import AddInformation from '@/components/Car/ErrorReport/AddInformation'
import SuccessInfo from '@/components/Car/ErrorReport/SuccessInfo'

export default {
  name: 'ErrorReportView',
  components: {
    CarTypeSelect,
    CarSubtypeSelect,
    AddInformation,
    AddDescription,
    SuccessInfo
  },
  data () {
    return {
      activeComponent: 'car-type-select',
      goBackToPage: 'car-type-select',
      errorData: {
        carTypeId: null,
        carSubType: null,
        identifier: null,
        description: ''
      }
    }
  },
  methods: {
    selectCarType (selectedCarType) {
      this.errorData.carType = selectedCarType
      this.activeComponent = 'car-subtype-select'
      this.goBackToPage = 'car-type-select'
    },
    selectCarSubtype (subType) {
      if (subType.default) {
        this.activeComponent = 'add-information'
        this.goBackToPage = 'car-type-select'
        return
      }
      this.errorData.carTypeId = subType.id
      this.activeComponent = 'add-information'
      this.goBackToPage = 'car-subtype-select'
    },
    addInformation (info) {
      this.errorData.identifier = info
      this.activeComponent = 'add-description'
      this.goBackToPage = 'add-information'
    },
    addDescription (description) {
      this.errorData.description = description
    },
    async reportError () {
      try {
        await this.$axios.post('/car-errors', this.errorData)
        this.activeComponent = 'success-info'
      } catch (err) {
        console.error(err)
      }
    },
    goBackTo (componentName) {
      this.activeComponent = componentName
      if (componentName === 'add-information' && this.errorData.carTypeId) {
        this.goBackToPage = 'car-subtype-select'
      } else {
        this.goBackToPage = 'car-type-select'
      }
    }
  }
}
</script>
